div:has(> .level-card) {
  position: relative;
  height: 32.5rem;
}

.level-card {
  position: absolute;
  display: flex;
  border-radius: 3.13rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  height: 32.5rem;
  width: 20.313rem;
  padding: 2.5rem;
}

.level-card-level {
  font-style: normal;
  font-family: "000 webfont";
  font-weight: 400;
  font-size: 1.813rem;
  line-height: 2.813rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.level-card-title {
  font-family: "Alba";
  font-style: normal;
  font-weight: 400;
  font-size: 2.75rem;
  line-height: 2.813rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.level-card-title-small {
  margin-bottom: 0.8rem;
  font-size: 2.3rem !important;
  line-height: 2.2rem !important;
}

.level-card-edition {
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1.188rem;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  letter-spacing: 0.5px;
}

.level-card-instructions {
  font-family: "Sora";
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.188rem;
  letter-spacing: 0.031rem;
}

.level-card-instructions-empty-actions {
  margin-bottom: 0;
  margin-top: 2.5rem;
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 31px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

/* ACTIVE */
.level-card--active {
  color: #ff5dc8;
  border: 1px solid #f251bc;
  background-color: #bc2b8b;
}

.level-card--active.active,
.level-card--active:hover .level-card-title,
.level-card--active:hover .level-card-instructions,
.level-card--active:hover .level-card-edition,
.level-card--active:hover .level-card-level,
.level-card--active.active .level-card-title,
.level-card--active.active .level-card-instructions,
.level-card--active.active .level-card-edition,
.level-card--active.active .level-card-level {
  color: #ffffff;
}

/* COMPLETED */
.level-card--completed {
  color: #f5f5f5;
  border: 1px solid #bf3948;
  background-color: #4b1f3e;
}

.level-card--completed .level-card-level,
.level-card--completed .level-card-title,
.level-card--completed .level-card-edition {
  color: #973540;
}

.level-card--completed .level-card-instructions,
.level-card--completed .level-card-instructions-empty-actions {
  opacity: 0.5;
}

.level-card--completed:hover .level-card-level,
.level-card--completed:hover .level-card-title,
.level-card--completed:hover .level-card-edition,
.level-card--completed:active .level-card-level,
.level-card--completed:active .level-card-title,
.level-card--completed:active .level-card-edition,
.level-card--completed.active .level-card-level,
.level-card--completed.active .level-card-title,
.level-card--completed.active .level-card-edition {
  color: #ff9fa9;
}

.level-card--completed:hover .level-card-instructions,
.level-card--completed:active .level-card-instructions,
.level-card--completed.active .level-card-instructions,
.level-card--completed:hover .level-card-instructions-empty-actions,
.level-card--completed:active .level-card-instructions-empty-actions,
.level-card--completed.active .level-card-instructions-empty-actions {
  opacity: 1;
}

/* LOCKED */
.level-card--locked {
  color: #828282;
  border: 1px solid #828282;
  background-color: #333333;
}

.level-card--locked.active,
.level-card--locked:active,
.level-card--locked:hover {
  color: #f2f2f2;
}


.level-card--next-locked {
  color: #828282;
  background-color: #333333;
  border: 2px solid rgba(242, 81, 188, 0.6);
  outline: 2px solid rgba(193, 84, 243, 0.6);
  outline-offset: 0.313rem;
}

.level-card--next-locked.active,
.level-card--next-locked:active,
.level-card--next-locked:hover {
  color: #ffffff;
  border: 2px solid rgba(242, 81, 188, 1);
  outline: 2px solid rgba(193, 84, 243, 1);
}


.level-card--locked .level-card-instructions {
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 2.438rem;
  line-height: 1.938rem;
  text-transform: uppercase;
}

/* NEXT */
.level-card--next {
  color: #828282;
  border: 2px solid #f251bc;
  background-color: #333333;
  outline: 2px solid #c154f3;
  outline-offset: 0.313rem;
}

.level-card--6::before {
  content: "";
  position: absolute;
  display: block;
  z-index: 15;
  background: url("../../images/card-bg-off.svg");
  background-repeat: no-repeat;
  top: -2rem;
  left: 4rem;
  bottom: 0rem;
  right: -1.5rem;
  background-size: contain;
}

.level-card--6.active::before {
  content: "";
  position: absolute;
  display: block;
  z-index: 15;
  background: url("../../images/card-bg-active.svg");
  background-repeat: no-repeat;
  top: -2rem;
  left: 4rem;
  bottom: 0rem;
  right: -1.5rem;
  background-size: contain;
}

.level-card--next:hover,
.level-card--next:active,
.level-card--next.active {
  color: #f2f2f2;
}

/* UPCOMMING */
.level-card--upcomming {
  color: #828282;
  border: 1px solid #828282;
  background-color: #333333;
}

.level-card--upcomming:hover,
.level-card--upcomming:active,
.level-card--upcomming.active {
  color: #f2f2f2;
}

.level-card--won {
  background: linear-gradient(180deg, #bf3948 1.15%, #962bc7 100%);
  border: 1px solid #ff9fa9;
}

.level-card--won .level-card-title {
  font-family: "Akka";
  opacity: 0.8;
  font-weight: normal;
  font-size: 2.175rem;
  line-height: 2.625rem;
  color: #ffffff;
  z-index: 20;
  min-height: 10rem;
}

.level-card--won::before,
.level-card--won.active::before {
  content: "";
  position: absolute;
  display: block;
  z-index: 15;
  background: url("../../images/won-card-bg.svg");
  background-repeat: no-repeat;
  top: -2.8rem;
  left: 0.6rem;
  bottom: -2.7rem;
  right: -1.9rem;
  background-size: cover;
}

/* Active level 1 */
.level-card--display--1--1 {
  z-index: 600;
  transform: scale(1);
}

.level-card--display--1--2 {
  z-index: 500;
  transform: scale(0.9);
  left: 9rem;
}

.level-card--display--1--3 {
  z-index: 400;
  transform: scale(0.8);
  left: 17rem;
}

.level-card--display--1--4 {
  z-index: 300;
  transform: scale(0.7);
  left: 24rem;
}

.level-card--display--1--5 {
  z-index: 200;
  transform: scale(0.6);
  left: 30rem;
}

.level-card--display--1--6 {
  z-index: 100;
  transform: scale(0.5);
  left: 35rem;
}

/* Active level 2 */
.level-card--display--2--1 {
  z-index: 500;
  transform: scale(0.8);
}

.level-card--display--2--2 {
  z-index: 600;
  left: 9rem;
  transform: scale(1);
}

.level-card--display--2--3 {
  z-index: 500;
  transform: scale(0.9);
  left: 17rem;
}

.level-card--display--2--4 {
  z-index: 300;
  transform: scale(0.8);
  left: 24rem;
}

.level-card--display--2--5 {
  z-index: 200;
  transform: scale(0.7);
  left: 30rem;
}

.level-card--display--2--6 {
  z-index: 100;
  transform: scale(0.6);
  left: 35rem;
}

/* Active level 3 */
.level-card--display--3--1 {
  z-index: 400;
  transform: scale(0.8);
}

.level-card--display--3--2 {
  z-index: 500;
  left: 9rem;
  transform: scale(0.9);
}

.level-card--display--3--3 {
  z-index: 600;
  transform: scale(1);
  left: 17rem;
}

.level-card--display--3--4 {
  z-index: 300;
  transform: scale(0.9);
  left: 24rem;
}

.level-card--display--3--5 {
  z-index: 200;
  transform: scale(0.8);
  left: 30rem;
}

.level-card--display--3--6 {
  z-index: 100;
  transform: scale(0.7);
  left: 35rem;
}

/* Active level 4 */
.level-card--display--4--1 {
  z-index: 300;
  transform: scale(0.7);
}

.level-card--display--4--2 {
  z-index: 400;
  left: 9rem;
  transform: scale(0.8);
}

.level-card--display--4--3 {
  z-index: 500;
  transform: scale(0.9);
  left: 17rem;
}

.level-card--display--4--4 {
  z-index: 600;
  transform: scale(1);
  left: 24rem;
}

.level-card--display--4--5 {
  z-index: 500;
  transform: scale(0.9);
  left: 30rem;
}

.level-card--display--4--6 {
  z-index: 400;
  transform: scale(0.8);
  left: 35rem;
}

/* Active level 5 */
.level-card--display--5--1 {
  z-index: 200;
  transform: scale(0.6);
}

.level-card--display--5--2 {
  z-index: 300;
  left: 7rem;
  transform: scale(0.7);
}

.level-card--display--5--3 {
  z-index: 400;
  transform: scale(0.8);
  left: 14.5rem;
}

.level-card--display--5--4 {
  z-index: 500;
  transform: scale(0.9);
  left: 22rem;
}

.level-card--display--5--5 {
  z-index: 600;
  transform: scale(1);
  left: 29rem;
}

.level-card--display--5--6 {
  z-index: 500;
  transform: scale(0.9);
  left: 35rem;
}

/* Active level 6 */
.level-card--display--6--1 {
  z-index: 100;
  transform: scale(0.5);
}

.level-card--display--6--2 {
  z-index: 200;
  left: 7rem;
  transform: scale(0.6);
}

.level-card--display--6--3 {
  z-index: 300;
  transform: scale(0.7);
  left: 14.5rem;
}

.level-card--display--6--4 {
  z-index: 400;
  transform: scale(0.8);
  left: 22rem;
}

.level-card--display--6--5 {
  z-index: 500;
  transform: scale(0.9);
  left: 29rem;
}

.level-card--display--6--6 {
  z-index: 600;
  transform: scale(1);
  left: 35rem;
}