/* Global Styles */
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=000 webfont:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500;800&family=Public+Sans:wght@200;400&family=Sora:wght@300;400;500;700&display=swap");

body {
  background-color: #1e1e1e;
  color: #e0e0e0;
  font-family: "Sora";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.5px;
}

::selection {
  background-color: #bc2b8ba6;
}

@font-face {
  font-family: "000 webfont";
  src: url("./fonts/000webfont\ Regular.ttf") format("truetype");
  /* Add other font properties if needed */
}

@font-face {
  font-family: "Akka";
  src: url("./fonts/akka.ttf") format("truetype");
  /* Add other font properties if needed */
}

@font-face {
  font-family: "Alba";
  src: url("./fonts/ALBA____.TTF") format("truetype");
  /* Add other font properties if needed */
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus,
input[type="number"]:active,
button,
button:hover,
button:focus,
button:active,
select,
select:hover,
select:focus,
select:active,
textarea,
textarea:hover,
textarea:focus,
textarea:active {
  -webkit-appearance: none !important;
  appearance: none !important;
  outline: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mainHeaderText {
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 39px;
  color: #f5f5f5;
}

.logo-text {
  display: flex;
  align-items: start;
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 2.813rem;
  text-align: center;
  letter-spacing: -0.08px;
  color: white;
}

.soraLight {
  font-family: "Sora";
  font-weight: 400;
}

.logo-addon {
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.563rem !important;
  color: #bdbdbd;
  margin-left: 0.459rem;
  line-height: 3.875rem;
}

.navBold {
  font-family: "000 webfont";
  font-weight: 700;
}

.navBarText:hover,
.logo-text:hover {
  text-decoration: none;
  color: white;
}

.navBarStartBtn {
  font-family: "000 webfont";
  font-size: 18px;
  background-color: #9d4dfa;
}

.pinkBtn {
  align-items: center;
  padding: 0px 5px;
  gap: 8px;
  width: 227px;
  height: 56px;
  /* WaveWrld/button pink */
  background: #e61ea3;
  border-radius: 100px;
}

.headerBtn {
  font-family: "Sora";
  font-size: 15px;
  background-color: #9d4dfa;
  padding: 6px;
}

.webfont {
  font-family: "000 webfont";
}

.belowHeaderText {
  font-size: 18px;
  font-family: "Sora";
  line-height: 22px;
}

.headerBox {
  width: 40%;
}

.modalButtonSignIn {
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border-radius: 30px;
  background: #ef22a9;
  color: rgb(255, 255, 255);
  width: 30%;
  height: 45px;
  text-transform: uppercase;
}

.modalButtonSignIn:hover {
  border: 2px solid #a50f73 !important;
}

.modalButtonSignIn:active {
  border: 2px solid #d9d9d9 !important;
  background-color: white !important;
  color: grey !important;
}

.modalButtonSignIn:focus {
  border: 2px solid #d9d9d9 !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.modalTitle {
  margin-top: 30px;
  color: #000d35;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;

  text-transform: uppercase;

  margin-left: auto;
  margin-right: auto;
}

.enter {
  margin-top: 30px;
  color: #ff6c7b;
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
}

.wavGameHeader {
  color: #ffffff;
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 80px;
  margin-top: -5%;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
}

.modalRow {
  flex: 1;
  flex-direction: row;
  margin-bottom: 15px;
}

.modalTerms {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  text-align: center;
  text-decoration: underline;
  color: #878787;
}

.modalTerms:hover {
  color: #878787;
}

.modal-close-x {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 9999;
  cursor: pointer;
}

.modal-header-notext {
  margin-top: 5%;
}

/* WaveWrld/Modal header */
.custom-modal {
  top: 0;
  left: 0;
  max-width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-modal .modal-content {
  background-color: #1e1e1e;
  color: #fff;
}

.modal-content {
  max-width: 100%;
  min-width: 97vw;
  min-height: 97vh;
  overflow: auto;
}

.modal-header-text {
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  text-align: center;
  color: #e0e0e0;
}

.line {
  border-bottom: 1px solid #828282;
  width: 80%;
  margin: 0 auto;
}

.lineNoCenter {
  border-bottom: 1px solid #828282;
  width: 80%;
}

.flexDirectionRow {
  display: flex;
  flex-direction: row;
}

.flexDirectionCol {
  display: flex;
  flex-direction: column;
}

.footer {
  flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  align-items: center;
  background-color: #272222;
}

.footer > div {
  height: 6.125rem;
}

.alignTextToRight {
  text-align: "right";
  margin: 0px auto 0px auto;
}

.leftModalContainer {
  width: 80rem;
  height: 70vh;
  border-right: 1px solid #828282;
  text-align: center;
}

.contentView {
  height: 70vh;
}

.leftModalContainerCreditcard {
  width: 140rem;
  border-right: 2px solid black;
  padding-left: 5%;
  padding-right: 5%;
}

.rightModalContainer {
  padding-left: 3%;
  padding-right: 3%;

  width: 50%;
}

.rightSubHeadingText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #bdbdbd;
}

.eyebrowLg {
  /* WaveWrld/Lg Eyebrow */
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.02em;
  color: #bdbdbd;
}

.rightSubHeadingTextSmall {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #bdbdbd;
}

.headingBold {
  font-weight: 700;
}

.headingThin {
  font-weight: 400;
}

.passwordInputBox {
  background-color: #36343b;
  height: 45px;
  color: #cac4d0;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  outline: none !important;
  outline-style: none;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 3px;
  margin: 15;
  width: 30%;
  padding-bottom: 10px;
}

.avatarImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.artistName {
  flex: 1;
  text-align: center;
}

.softPink {
  color: #ffa3da !important;
}

.choose-artist-title {
  font-family: "Alba";
  font-style: normal;
  font-weight: 400;
  font-size: 3.125rem;
  line-height: 4.913rem;
  text-align: center;
  color: #ffa3da;
}
.defaultArtistBtn {
  background: #4b1f3e;
  /* WaveWrld/Cards/completed stroke */
  padding: 5px;
  padding-bottom: 12px;
  margin-left: 10px;
  width: 230px;
  border: 1px solid #bf3948;
  border-radius: 45px;
  display: flex;
  align-items: center;
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.defaultArtistBtn:hover {
  background: #7f2d67;
}

.passwordInputBox:focus {
  outline: none !important;
  box-shadow: none;
  border-bottom: solid #cac4d0 1px;
}

.inputNumberIncrement {
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  width: 30%;
  font-size: 16px;
  text-align: center;
  margin-left: 35%;
  margin-top: -5%;
}

button.xmint-btn span {
  display: none;
}

button.xmint-btn {
  background-color: #e61ea3;
  width: 40%;
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 100px;
  margin-top: 10px;
}

button.xmint-btn:after {
  content: "BUY WITH CREDIT CARD";
  color: white;
}

.skip-btn {
  margin-left: 10px;
  background-color: #e61ea3;
  width: 30%;
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 100px;
  margin-top: 10px;
}

.skip-btn:after {
  color: white;
}

.containerHomePage {
  border-radius: 31px;
  border: 1px solid #973540;
  width: 194px;
  height: 224px;
}

.secondContainerHome {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  opacity: 0.1;
}

.thirdContainerHome {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  opacity: 0.2;
}

div.background {
  background-color: beige;
  border: 2px solid black;
}

#container {
  position: relative;
  border-radius: 31px;
  border: 1px solid #973540;
  width: 20%;
  height: 224px;
}

#block {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  filter: alpha(opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.2;
  border-radius: 31px;
  height: 100%;
  width: 100%;
}

#blockOne {
  background: #2d2727;
  border-radius: 31px;
  height: 100%;
  width: 100%;
}

#blockTwo {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  filter: alpha(opacity=10);
  -moz-opacity: 0.1;
  opacity: 0.1;
  border-radius: 31px;
  height: 100%;
  width: 100%;
}

#blockThree {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  filter: alpha(opacity=20);
  -moz-opacity: 0.2;
  opacity: 0.2;
  border-radius: 31px;
  height: 100%;
  width: 100%;
}

#blockFour {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  filter: alpha(opacity=34);
  -moz-opacity: 0.34;
  opacity: 0.34;
  border-radius: 31px;
  height: 100%;
  width: 100%;
}

#blockFive {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  filter: alpha(opacity=54);
  -moz-opacity: 0.54;
  opacity: 0.54;
  border-radius: 31px;
  height: 100%;
  width: 100%;
}

#blockSix {
  background: linear-gradient(359.34deg, #bf3948 0.46%, #962bc7 99.33%);
  border-radius: 31px;
  height: 100%;
  width: 100%;
}

#text {
  position: absolute;
  left: 16.19%;
  right: 48.57%;
  top: 12.03%;
  bottom: 80.45%;
  /* WaveWrld/Level bars */
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 20px;
  /* identical to box height, or 77% */
  /* WaveWrld/light coral */
  color: #ff9fa9;
  height: 100%;
  width: 100%;
}

.coral {
  color: #ff6979 !important;
}

.lineCoral {
  border: 1px solid #973540;
  width: 90%;
  margin: auto;
}

.lightPink {
  color: #f251bc !important;
}

.lightCoral {
  color: #ff9fa9 !important;
}

.userAvatar {
  padding: 10;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 1px solid #f5f5f5;
  background-color: transparent;
  outline: 1px solid #bc2b8b;
  outline-offset: 0.313rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.userMenuText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
}

.userMenuDiv {
  top: 6rem;
  background-color: #262020;
  letter-spacing: 1px;
  border: 1px solid #4f4f4f;
  border-radius: 20px;
}

.userMenuAddressText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #cac4d0;
}

.welcomeToWavGame {
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 86px;
  line-height: 56px;
  /* identical to box height, or 65% */

  background: linear-gradient(90.02deg, #fe7a8b -1.51%, #b721eb 87.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.lighterGreyHomeContainer {
  background-color: #262020;
  height: 40%;
  width: 100%;
  border-bottom: 1px solid #973540;
  border-top: 1px solid #973540;
}

.levelCardText {
  position: absolute;

  left: 15.28%;
  right: 16.17%;
  top: 28.57%;
  bottom: 46.62%;
}

.levelCardSvg {
  position: absolute;
  left: 60.28%;
  right: 16.17%;
  top: 70.57%;
  bottom: 40.62%;
}

.levelCardUnion {
  position: absolute;
  left: 45.28%;
  top: 80%;
  bottom: 0%;
}

.builtWithLiqSDKContainer {
  background: #262020;
  /* WaveWrld/dark orange */
  border: 1px solid #973540;
  height: 270px;
  padding: 2rem 5rem;
  width: 80%;
  border-radius: 53px;
}

.progressbar {
  position: relative;
  overflow: hidden;
  width: 350px;
  height: 9px;
  background-color: #4f4f4f;
}

.progressPercent {
  font-weight: 600;
  font-family: "Sora";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #eee;
  text-shadow: -1px 0 #555, 0 1px #555, 1px 0 #555, 0 -1px #555;
}

.custom-button:hover,
.custom-button:focus {
  background: #ff82af !important;
  border-radius: 100px;
  color: white !important;
}

.custom-button:active {
  background: #bb007b !important;
  border-radius: 100px;
  color: white !important;
}

.disabledBtn {
  background: #4f4f4f;
  color: white !important;
  border-radius: 100px;
}

.violet {
  color: #c154f3 !important;
}

.nftImageSendMany {
  border-radius: 20px;
  width: 120.57px;
  height: 156.17px;
}

.nftImageSendTwo {
  border-radius: 20px;

  width: 247.41px;
  height: 319.27px;
}

.nftImageSendOne {
  border-radius: 20px;

  width: 272px;
  height: 351px;
}

.nftImagePrepared {
  width: 367px;
  height: 472px;
  border-radius: 20px;
}

.greySmallText {
  /* WaveWrld/body copy */

  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  /* or 136% */

  letter-spacing: 0.5px;

  /* Gray 5 */

  color: #e0e0e0;
}

.greyUpperCaseText {
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  /* identical to box height, or 146% */

  letter-spacing: 0.5px;

  /* Gray 3 */

  color: #828282;
}

.nftPreviewTrade {
  width: 176px;
  height: 220px;
  border-radius: 20px;
}
.nftBigPreviewTrade {
  width: 336px;
  height: 430px;
  border-radius: 20px;
}

.termsUpper {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  text-transform: capitalize;
}

.terms {
  font-family: "Verdana";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
}

/*********/

/* ------------ */

.card {
  background-color: transparent;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.3px;

  overflow: hidden;
}
.rc-accordion-toggle {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
  background-color: transparent;
  transition: 0.3s;
}
.rc-accordion-toggle.active {
  background-color: transparent;
}
.rc-accordion-toggle.active .rc-accordion-icon {
  transform: rotate(180deg);
  color: #fff;
}
.rc-accordion-card {
  margin-bottom: 10px;
  overflow: hidden;
}
.rc-accordion-card:last-child {
  margin-bottom: 0;
}
.rc-accordion-title {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-transform: uppercase;
  /* identical to box height, or 147% */

  letter-spacing: 1px;

  /* Greys/White */

  color: #ffffff;
}
.rc-accordion-toggle.active .rc-accordion-title {
  color: #fff;
}
.rc-accordion-icon {
  position: relative;
  top: 2px;
  color: white;
  transition: 0.35s;
  font-size: 12px;
}
.rc-accordion-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 15px;
}
.rc-accordion-body p {
  margin-bottom: 0;
  font-size: 14px;

  color: white;
}
.rc-collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.rc-collapse.show {
  height: auto;
}

.button {
  background-color: #e61ea3;
  border-radius: 100px;
  color: white;
  padding: 0.5em;
  width: "227px";
  height: "56px";
  text-decoration: none;
  padding: 15px;
}

.button:focus,
.button:hover {
  background-color: #e61ea3;
  color: White;
  text-decoration: none;
}

.content {
  display: none;
  margin: 1em 0;
}

.content.active,
.no-js .content {
  display: block;
}

.nft-game-incentives {
  width: 406px;
  height: 515px;
}

.nft-game-incentives::before {
  content: "";
  position: absolute;
  display: block;
  width: 406px;
  height: 515px;
  z-index: 1;
  background: url("./images/nft-squares.svg");
  background-repeat: no-repeat;

  bottom: 0;
  right: -2.5rem;
  background-size: contain;
  top: 2rem;
  left: 1.5rem;
}
.nft-game-incentives img {
  width: 406px;
  height: 515px;
  z-index: 999;
}
