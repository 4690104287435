.level-tab-title {
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 1.053rem;
    font-family: "000 webfont";
    font-style: normal;
    font-weight: 400;
    font-size: 1.313rem;
    line-height: 1.25rem;
}

.level-tab-title--active {
    color: #F251BC;
}

.level-tab-title--completed {
    color: #FF6979;
}

.level-tab-title--locked {
    color: #828282;
}

.level-tab-title--upcomming {
    color: #828282;
}

.level-tab-title--won {
    color: #962BC7;
}


.level-tab-control {
    height: 0.625rem;
    width: 8.563rem;
    border-radius: 3.313rem;
    cursor: pointer;
}

.level-tab-control--active {
    background-color: #BC2B8B;
    border: 1px solid #F251BC;
    opacity: 0.6;
}

.level-tab-control--active:hover,
.level-tab-control--active.active {
    background-color: #BC2B8B;
    border: 1px solid #F251BC;
    opacity: 1;
}

.level-tab-control--completed {
    background-color: #272222;
    border: 1px solid #BF3948;
    opacity: 0.6;
}

.level-tab-control--completed:hover,
.level-tab-control--completed.active {
    background-color: #BF3948;
    border: 1px solid #BF3948;
    opacity: 1;
}

.level-tab-control--locked {
    background-color: #272222;
    border: 1px solid #828282;
}

.level-tab-control--locked:hover,
.level-tab-control--locked.active {
    background-color: #828282;
    border: 1px solid #828282;
}

.level-tab-control--next {
    border: 1px solid #F251BC;
    background-color: #333333;
    outline: 1px solid #C154F3;
    outline-offset: 1px;
    opacity: 0.6;
}

.level-tab-control--upcomming {
    border: 1px solid #828282;
    background-color: #272222;
    opacity: 0.6;
}

.level-tab-control--upcomming:hover,
.level-tab-control--upcomming.active  {
    border: 1px solid #828282;
    background-color: #828282;
}

.level-tab-control--next:hover,
.level-tab-control--next.active,
.level-tab-control--upcomming:hover,
.level-tab-control--upcomming.active {
    opacity: 1;
}

.level-tab-control--won {
    background-color: #962BC7;
    border: 1px solid #962BC7;
}