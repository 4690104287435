@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --dark: #211C1C;
    }
}

.bg-dark {
    background-color: #211C1C !important;
  }


ul.list {
    list-style: disc;
    list-style-position: inside;
}
ul.list li {
    margin-bottom: 1rem;
}
