.side-bar {
  display: flex;
  align-items: end;
  background-color: #252020;
  border: 1px solid #973540;
  border-radius: 3.31rem;
  margin-bottom: 2rem;
  z-index: 333 !important;
}

.side-bar-secondary {
  background-color: #2d2727;
  border: 1px solid #973540;
  border-radius: 3.31rem;
  position: relative;
  margin-top: 12rem;
  z-index: 13;
}

.level-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.level-tabs li {
  float: left;
  display: flex;
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.625rem;
  line-height: 1.25rem;
  flex-direction: column;
  justify-content: space-between;
  margin: 1rem;
  cursor: pointer;
}

.level-tabs li .level-bar {
  height: 0.625rem;
  width: 8.563rem;
  border-radius: 3.31rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.bio-artist-name,
.artist-name {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 2.188rem;
  letter-spacing: 0.02em;
}

.bio-artist-name {
  color: #e0e0e0;
}

.artist-name {
  color: #ff9fa9;
}

.artist-desc,
.bio-artist-desc {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 1.125rem;
  letter-spacing: 0.02em;
  text-transform: capitalize;
}
.bio-artist-desc {
  color: #bdbdbd;
}

.artist-desc {
  color: #ff9fa9;
}

.artist-link {
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.03em;
  color: #f251bc;
}

.artist-wave-graphic {
  align-self: center;
  position: absolute;
  top: -6.8rem;
}

.artist-content {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.04em;
}

.artist-info {
  border-top: 1px solid #bf3948;
}

.artist-image {
  width: 14.875rem;
  height: 14.875rem;
  border-radius: 3.063rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.artist-image-bio {
  width: 10.438rem;
  height: 10.438rem;
  border-radius: 3.063rem;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #828282;
}

.artist-content h2,
.artist-info h2 {
  text-transform: uppercase;
  font-family: "Sora";
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1.375rem;
  letter-spacing: 1px;
}
.artist-content p,
.artist-info p {
  font-family: "Sora";
  font-style: normal;
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.188rem;
}

.game-header {
  border-top: 1px solid #bf3948;
}

.game-header-level,
.game-header-counter {
  font-family: "000 webfont";
  font-style: normal;
  font-weight: 400;
  font-size: 1.625rem;
  line-height: 1.563rem;
  color: #e0e0e0;
}

.game-header-title {
  font-family: "Alba";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3.688rem;
  text-align: center;
  color: #e0e0e0;
}

.faq-header-title {
  font-family: "Alba";
  font-style: normal;
  font-weight: 400;
  font-size: 1.563rem;
  line-height: 3.688rem;
  color: #e0e0e0;
}

.leaderboardContainer {
  width: 100%;
  height: 150px;
  border-bottom: 1px solid #828282;
  border-top: 1px solid #828282;
}

.faqContainer {
  width: 100%;
}

.leaderboardToggle {
  line-height: 27px;
  top: -30%;
  font-size: 13px;
  color: #bdbdbd;
}

.nftsToggle {
  top: -30%;
  left: 14%;
  line-height: 27px;
  font-size: 13px;
  color: #bdbdbd;
}

.sendNfts {
  top: -20%;
  font-size: 13px;
  color: "#f251bc";
  line-height: 27px;
  text-transform: uppercase;
}
